<template>
  <div>
    <!--PVUV-->
    <div class="mainAll1">
      <div class="navigatorAll">
        <span
          style="border-right:none"
          @click="active('activeAll1',1)"
          :class="{'active':activeAll1===1}"
        >{{$t('Annual')+$t('Data')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll1',2)"
          :class="{'active':activeAll1===2}"
        >{{$t('Quarterly')+$t('Data')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll1',3)"
          :class="{'active':activeAll1===3}"
        >{{$t('Monthly')+$t('Data')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll1',4)"
          :class="{'active':activeAll1===4}"
        >{{$t('Weekly')+$t('Data')}}</span>
        <span
          @click="active('activeAll1',5)"
          :class="{'active':activeAll1===5}"
        >{{$t('Daily')+$t('Data')}}</span>
      </div>
      <div class="chartAll1">
        <div
          id="lineAll1"
          class="lineAll1"
        >
        <!--<h1>折线图使用的假数据</h1>-->
        </div>
        <div class="pieAll1">
          <div id="pieAll1"></div>
          <div>
            <div class="pvuv">
              <p>PV</p>
              <p>{{UNum[0]}}</p>
            </div>
            <div class="pvuv">
              <p>UV</p>
              <p>{{UNum[1]}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import api from "@/fetch/api";
import moment from "moment";

export default {
  name: "",
  components: {},
  data() {
    //读取本地个人信息
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      activeAll1: 1,
      activeAll2: 1,
      chamberId: data.chamberId,
      tenantCode: data.tenantCode,
      today: "",

      UNum: {},
      initData: {
        chamberId: data.chamberId,
        date: "",
        currentAdmin: data.id,
      },
    };
  },
  mounted() {
    const param = { dateType: "YEAR", requestType: [1] };
    this.getData(param);

    this.today = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
  },
  methods: {
    active(tag, num) {
      this[tag] = num;
      const dateObj = {
        1: "YEAR",
        2: "QUARTER",
        3: "MOUTH",
        4: "WEEK",
        5: "DAY",
      };

      let requestType = 1;
      const param = { dateType: dateObj[num], requestType: [requestType] };
      this.getData(param);
    },

    //获取数据
    getData({ dateType, requestType }) {
      let params = {
        dateType: dateType === "QUARTER" ? "YEAR" : dateType,
        dataType: 4,
        requestType: requestType,
        chamberId: this.chamberId,
        tenantCode: this.tenantCode,
      };
      api.queryStatisticsData(params).then((res) => {
        if (requestType[0] === 1) {
          const listTime = res.data.brokenLineData.listTime,
            listDataY1 = res.data.brokenLineData.listDataY1,
            listDataY2 = res.data.brokenLineData.listDataY2;
          res.data.brokenLineData.listTime = this.timeDateFormat(dateType, listTime);
          if (dateType === "QUARTER") {
            res.data.brokenLineData.listDataY1 = this.dataFormat(listDataY1);
            res.data.brokenLineData.listDataY2 = this.dataFormat(listDataY2);
          }
          this.drawLineChart1(res.data);
          this.drawPieChart1(res.data);
          this.UNum = {
            0: Number(res.data.brokenLineData.resultParamOne),
            1: Number(res.data.brokenLineData.resultParamTwo),
          };
        }
      });
    },

    //季度数据合并
    dataFormat(arr, start, end) {
      let result = [];
      for (let i = 0; i <= arr.length - 3; i+=3) {
        let sum = Number(arr[i]) + Number(arr[i+1]) + Number(arr[i+2]);
        result.push(sum);
      }
      return result;
    },

    //时间日期转化
    timeDateFormat(dateType, listTime) {
      switch (dateType) {
        case "QUARTER":
          listTime = this.formatQuarter(listTime);
          break;
        case "MOUTH":
          listTime = this.formatMonthDay(listTime);
          break;
        case "WEEK":
          listTime = this.formatWeekday(listTime);
          break;
        case "DAY":
          listTime = this.formatTime(listTime);
          break;
      }
      return listTime;
    },

    //合为季度
    formatQuarter(arr) {
      let result = [];
      for (let i = 0; i < arr.length; i += 3) {
        let start = arr[i];
        let end = arr[i + 2] ? arr[i + 2] : arr[arr.length - 1];
        result.push(start + "~" + end);
      }
      return result;
    },

    //月、周转换
    formatDates(arr) {
      let result = [];
      let resultWeek = [];
      for (let i = 0; i < arr.length; i++) {
        let date = new Date(arr[i]);
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        let day = date.getDate();
        if (day < 10) day = "0" + day;
        let weekday = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()];
        result.push(month + "-" + day);
        resultWeek.push(month + "-" + day + "(" + weekday + ")");
      }
      return { result, resultWeek };
    },

    //月度
    formatMonthDay(arr) {
      return this.formatDates(arr).result;
    },

    //本周
    formatWeekday(arr) {
      return this.formatDates(arr).resultWeek;
    },

    //当日
    formatTime(arr) {
      let result = [];
      for (let i = 0; i < arr.length; i++) {
        let time = arr[i].split(" ")[1].split(":");
        let hour = time[0];
        let minute = time[1];
        result.push(hour + ":" + minute);
      }
      return result;
    },

    //折线图1
    drawLineChart1(data) {
      const brokenLineData = data.brokenLineData;
      let date = this.activeAll1;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("lineAll1"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#F3B1CA", "#99B6ED"],
        title: {
          text: "PV UV",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["PV", "UV"],
          icon: "rect",
        },
        xAxis: {
          data: brokenLineData.listTime,
          axisLabel: {
            interval: date === 2 || date === 4 ? 0 : 2, // 0 强制显示所有坐标
          },
        },
        yAxis: {},
        series: [
          {
            data: brokenLineData.listDataY1,
            type: "line",
            name: "PV",
            areaStyle: {
              opacity: 0.5,
              color: "#F3B1CA",
            },
            // emphasis: {
            //   focus: 'series'
            // },
          },
          {
            data: brokenLineData.listDataY2,
            type: "line",
            name: "UV",
            areaStyle: {
              opacity: 0.5,
              color: "#99B6ED",
            },
            // emphasis: {
            //   focus: 'series'
            // },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    //根据type来分类总和
    typesPVUV(dataset) {
      let typeList = [];
      typeList.push({ name: this.$t("LawQA"), value: dataset[0] });
      typeList.push({ name: this.$t("VideoOnline"), value: dataset[1] });
      typeList.push({ name: this.$t("ContractDownload"), value: dataset[2] });
      typeList.push({ name: this.$t("CrossBoard"), value: dataset[3] });
      return typeList;
    },

    //环状图嵌套仪表盘1
    drawPieChart1(data) {
      const brokenLineData = data.brokenLineData,
        pieChartData = data.pieChartData;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("pieAll1"));
      let max = Number(brokenLineData.resultParamOne);
      let percent = max
        ? (Number(brokenLineData.resultParamTwo) * 100) / max
        : 0;
      // 指定图表的配置项和数据
      let option = {
        tooltip: {},
        series: [
          {
            name: "UV/PV",
            type: "gauge",
            min: 0,
            max: max,
            minInterval: 1,
            splitNumber: max < 10 ? max : 10,
            radius: "70%",
            tooltip: {
              trigger: "item",
              formatter: "{a} <br/>{b}: " + percent.toFixed(2) + "%", //自定义显示格式(a: seriesName  b:name, c:value, d:百分比)
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}",
            },
            axisLabel: {
              // 坐标轴小标记
              distance: 12,
              fontSize: 12,
              formatter: function (v) {
                return Number.isNaN(v) ? 0 : v.toFixed(0);
              },
            },
            label: {
              normal: {
                position: "inside",
                align: "right",
                fontSize: 18,
                fontFamily: "Microsoft YaHei",
                color: "#FFEFE0",
              },
            },
            data: [
              {
                value: Number(brokenLineData.resultParamTwo),
                name: this.$t('CrossBoard'),
              },
            ],
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
};
</script>

<style  lang="scss" scoped>
.active {
  /*background-color: #1C252C;*/
  background-color: #677d95;
  color: white;
}
.mainAll1 {
  /*height:450px;*/
  width: 1600px;
}
.navigatorAll {
  /*width: 1660px;*/
  width: 99%;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /*border:1px solid black;*/
  margin: 10px auto;
  & > span {
    line-height: 40px;
    text-align: center;
    border: 1px solid black;
  }
}
.chartAll1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.lineAll1 {
  height: 350px;
  width: 45%;
  margin: 10px;
}
.pieAll1 {
  height: 350px;
  width: 55%;
  margin: 10px;
  display: flex;
  justify-content: space-around;
  #pieAll1 {
    width: 740px;
    height: 350px;
  }
  #pieAll2 {
    width: 740px;
    height: 350px;
  }
}
.pvuv {
  border: 1px solid black;
  width: 200px;
  height: 100px;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column nowrap;
  font-size: 20px;
}
</style>